import {
  CaretDownOutlined,
  CloudUploadOutlined,
  EditOutlined,
  FileExcelOutlined,
  InfoCircleTwoTone,
  ShoppingCartOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  Popover,
  Row,
  Select,
  Spin,
  Switch,
  Table,
  Tooltip,
  notification,
} from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import CondicionesModal from '../../../../components/bills/condicionesModal/condicionesModal';
import CufeMasivoModal from '../../../../components/bills/cufeMasivoModal/cufeMasivoModal';
import CufeModal from '../../../../components/bills/cufeModal/cufeModal';
import CufeText from '../../../../components/bills/cufeText/cufeText';
import history from '../../../../services/history';
import billServices from '../../../../services/proveedor/billServices';
import {
  nextStep,
  programNextStep,
  programSelectedBills,
  selectedBills,
  updateProgramDate,
} from '../../../../store/actions/proveedor/billingActions';
import store from '../../../../store/index';
import '../../../../styles/standard.scss';
import {
  BILLING_SUBTITLE_INIT,
  BILLING_TIME_ERROR,
  BILLING_TITLE_INIT,
  PROGRAM_SUBTITLE_INIT,
  PROGRAM_TITLE_INIT,
} from '../../../../utilities/messages';
import {
  currencyFormat,
  sorterDate,
  sorterText,
} from '../../../../utilities/textTools';
import './bill.scss';

const { Option } = Select;
const { Search } = Input;
class Bill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      investors: [],
      selectedInvestor: '',
      selectedBusinessGroup: '',
      programSelectedInvestor: '',
      programSelectedBusinessGroup: '',
      fechaSeleccionada: '',
      listedBills: [],
      programListedBills: [],
      selectedRowKeys: [],
      selectedRows: [],
      redirect: false,
      loading: false,
      fechasActivas: [],
      cufeVisible: false,
      condicionVisible: false,
      condicionDataFondeador: [],
      offerCufe: {},
      sort: 'notasCredito',
      cufeMasivoVisible: false,
    };

    this.businessGroupSelect = React.createRef();

    this.cards = [];
  }
  getBillsColumns = (cdTipoFe) => {
    const billsColumns = [
      {
        title: '# FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'SOCIEDAD',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'EMISIÓN',
        dataIndex: 'feEmision',
        sorter: (a, b) => sorterDate(a.feEmision, b.feEmision),
        showSorterTooltip: false,
      },
      {
        title: 'VENCIMIENTO',
        dataIndex: 'feVencimiento',
        sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR PENDIENTE',
        dataIndex: 'vrFactura',
        render: (value) => <span>{currencyFormat(value)}</span>,
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        showSorterTooltip: false,
      },
      {
        title: 'OFERTA DE COMPRA',
        dataIndex: 'vrOferta',
        render: (text) => (
          <span className='offer-value'>{currencyFormat(text)}</span>
        ),
        sorter: (a, b) => a.vrOferta - b.vrOferta,
        showSorterTooltip: false,
      },
      {
        title: 'NOTAS',
        dataIndex: 'notasCredito',
        render: (object) =>
          object.length > 0 ? <span className='has-credits'>N </span> : '',
        sorter: (a, b) => {
          if (a.notasCredito != null && b.notasCredito != null) {
            if (a.notasCredito.length > 0 && b.notasCredito.length <= 0) {
              return -1;
            }
            if (a.notasCredito.length <= 0 && b.notasCredito.length > 0) {
              return 1;
            }

            if (a.notasCredito.length > 0 && b.notasCredito.length > 0) {
              return 0;
            }
          }
        },
        showSorterTooltip: false,
      },
      {
        title: 'TIPO CUPO',
        dataIndex: 'cdTipoCupo',
        sorter: (a, b) => sorterText(a.cdTipoCupo, b.cdTipoCupo),
        showSorterTooltip: false,
      },
      {
        title: 'CUFE',
        dataIndex: '',
        render: (object) => (
          <Row align='middle'>
            <Col flex='auto'>
              <CufeText cufe={object.cufe} />
            </Col>
            <Col flex='50px'>
              <Tooltip title={object.cufe ? 'Editar CUFE' : 'Agregar CUFE'}>
                <Button
                  className='btn-cufe'
                  onClick={() => this.openCufeModal(object)}
                  icon={<EditOutlined />}
                />
              </Tooltip>
            </Col>
          </Row>
        ),
        sorter: (a, b) => sorterText(a.cufe, b.cufe),
        showSorterTooltip: false,
        cdTipoFe: 'RADIAN',
      },
      {
        title: '',
        dataIndex: 'inCart',
        render: (text) =>
          text && (
            <span className='current-founder'>
              {text} <ShoppingCartOutlined />
            </span>
          ),
      },
      {
        title: '',
        dataIndex: 'detail',
        render: (text) => (
          <Popover placement='topRight' content={text} title='Detalle'>
            <InfoCircleTwoTone className='info' />
          </Popover>
        ),
      },
    ];

    return billsColumns.filter(
      (el) => el.cdTipoFe === undefined || el.cdTipoFe === cdTipoFe
    );
  };

  openCufeModal = (oferta) => {
    this.setState({
      ...this.state,
      offerCufe: oferta,
      cufeVisible: true,
    });
  };

  creditsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFacturaRest',
      sorter: (a, b) => sorterText(a.cdFacturaRest, b.cdFacturaRest),
      showSorterTooltip: false,
    },
    {
      title: 'SOCIEDAD',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmisionRest',
      render: (text) => text,
      sorter: (a, b) => sorterDate(a.feEmisionRest, b.feEmisionRest),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimientoRest',
      render: (text) => text,
      sorter: (a, b) => sorterDate(a.feVencimientoRest, b.feVencimientoRest),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR PENDIENTE',
      dataIndex: 'vrFacturaRest',
      render: (value) => <span>{currencyFormat(value)}</span>,
      sorter: (a, b) => a.vrFacturaRest - b.vrFacturaRest,
      showSorterTooltip: false,
    },
  ];

  creditReducer = (accumulator, currentValue) =>
    accumulator + currentValue.vrFacturaRest;

  offerReducer = (accumulator, currentValue) =>
    accumulator + currentValue.vrOferta;

  componentDidUpdate(props, state) {
    if (this.props.module !== 'program') {
      if (props.billing.company !== this.props.billing.company) {
        this.props.selectedBills({
          selectedBills: [],
          totalSelectedValue: 0,
        });
        const selectedCompany = this.props.billing.company;
        if (selectedCompany !== '') {
          this.setState({
            ...this.state,
            loading: true,
          });
          const token = store.getState().auth.auth.access_token;
          billServices
            .listarPreofertas(token, selectedCompany)
            .then((response) => {
              if (response.data.length > 0) {
                this.setState({
                  selectedInvestor: '',
                  selectedBusinessGroup: '',
                  listedBills: [],
                  totalOffer: 0,
                  selectedRowKeys: [],
                  selectedRows: [],
                  investors: response.data,
                  loading: false,
                });
              } else {
                this.setState({
                  ...this.state,
                  redirect: true,
                  loading: false,
                });
              }
            })
            .catch((error) => {
              notification.error({
                message: 'Ha ocurrido un error',
                description: error.response.data.message,
                duration: 4,
              });
            });
        }
      }
    }
    if (this.props.module === 'program') {
      if (this.state.fechaSeleccionada !== '') {
        if (
          state.programSelectedInvestor.scFondeador !==
          this.state.programSelectedInvestor.scFondeador
        ) {
          this.businessGroupSelection(
            'all',
            this.state.programSelectedInvestor
          );
        }
      }
    } else {
      if (
        state.selectedInvestor.scFondeador !==
        this.state.selectedInvestor.scFondeador
      ) {
        this.businessGroupSelection('all', this.state.selectedInvestor);
      }
    }
  }

  validarFechasActivas = () => {
    const selectedCompany = store.getState().billing.company;
    const token = store.getState().auth.auth.access_token;
    billServices
      .fechasActivasProgramacion(token, selectedCompany)
      .then((response) => {
        let fechas = response.data;
        if (Array.isArray(fechas)) {
          this.setState({
            ...this.state,
            fechasActivas: fechas.map((fecha) => moment(fecha)),
          });
        }
      });
  };

  componentDidMount() {
    const selectedCompany = store.getState().billing.company;
    this.validarFechasActivas();
    if (selectedCompany !== '') {
      if (this.props.module !== 'program') {
        this.setState({
          ...this.state,
          loading: true,
        });
        const token = store.getState().auth.auth.access_token;
        billServices
          .listarPreofertas(token, selectedCompany)
          .then((response) => {
            if (response.data.length > 0) {
              if (this.props.billing.selectedBills.length > 0) {
                var selectedInvestor = response.data.find(
                  (investor) =>
                    investor.scFondeador ===
                    this.props.billing.selectedBills[0].scFondeador
                );
              }
              this.setState({
                ...this.state,
                investors: response.data,
                selectedInvestor: selectedInvestor || '',
                loading: false,
              });
            } else {
              this.setState({
                ...this.state,
                redirect: true,
                loading: false,
              });
            }
          });
      } else {
        const { programDate } = store.getState().billing;
        if (programDate !== '') {
          this.getProgramInvestors(programDate);
        }
      }
    }
  }

  handleOpenCondicionModal = (investor) => {
    this.state.condicionVisible = true;
    let dataFondeador = {
      nombre: investor.nombreFondeador,
      scFondeador: investor.scFondeador,
      mensajeFondeador: investor.mensajeFondeadorCondicion,
    };
    this.state.condicionDataFondeador = dataFondeador;
    this.setState({
      ...this.state,
      condicionVisible: true,
      condicionDataFondeador: dataFondeador,
    });
  };
  handleCondicionOnClose = () => {
    this.setState({
      ...this.state,
      condicionVisible: false,
    });
  };

  formatCreditDate(date) {
    var newDate = new Date(date);
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var day = newDate.getDate();
    month = month < 9 ? '0' + month.toString() : month;
    day = day < 9 ? '0' + day.toString() : day;
    newDate = (year + '-' + month + '-' + day).toString();
    return newDate;
  }

  formatInvestorDate = (date) => {
    var newDate = {};
    newDate.fullDate = new Date(date);
    newDate.day = newDate.fullDate.getDay();
    newDate.month = newDate.fullDate.getMonth();
    newDate.dayNumber = newDate.fullDate.getDate();
    var currentDate = {};
    currentDate.fullDate = new Date();
    currentDate.day = currentDate.fullDate.getDay();
    currentDate.month = currentDate.fullDate.getMonth();
    currentDate.dayNumber = currentDate.fullDate.getDate();
    if (
      newDate.day === currentDate.day &&
      newDate.month === currentDate.month &&
      newDate.dayNumber === currentDate.dayNumber
    ) {
      return 'Hoy';
    } else {
      const months = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ];
      const days = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
      ];
      return (
        days[newDate.day] +
        ' ' +
        newDate.dayNumber +
        ' de ' +
        months[newDate.month]
      );
    }
  };

  investorSelection(e) {
    if (e.aprobado) {
      if (this.props.module === 'program') {
        this.setState({
          ...this.state,
          programSelectedInvestor: e,
          programSelectedBusinessGroup: {
            scGrupo: 'all',
          },
          selectedRows: [],
          loading: true,
        });
      } else {
        this.setState({
          ...this.state,
          selectedInvestor: e,
          selectedBusinessGroup: {
            scGrupo: 'all',
          },
          selectedRows: [],
          loading: true,
        });
      }
      setTimeout(() => {
        this.setState({
          ...this.state,
          loading: false,
        });
        var section = document.getElementById('bill-selection-section');
        if (section) {
          section.scrollIntoView();
        }
      }, 1000);
    }
  }

  businessGroupSelection = (value, e) => {
    var selectedInvestor = '';
    if (this.props.module === 'program') {
      selectedInvestor = this.state.programSelectedInvestor;
    } else {
      selectedInvestor = this.state.selectedInvestor;
    }
    const selectedCompany = store.getState().billing.company;
    const token = store.getState().auth.auth.access_token;
    if (this.props.module === 'program') {
      this.setState({
        ...this.state,
        programListedBills: [],
        loading: true,
        programSelectedBusinessGroup: {
          scGrupo: 'all',
        },
      });
    } else {
      this.setState({
        ...this.state,
        listedBills: [],
        loading: true,
        selectedBusinessGroup: {
          scGrupo: 'all',
        },
      });
    }
    if (this.props.module !== 'program') {
      if (value !== 'all') {
        billServices
          .listarOfertaCompra(
            token,
            selectedCompany,
            selectedInvestor.scFondeador,
            value
          )
          .then((response) => {
            var objBusinessGroup = {};
            objBusinessGroup.totalOffer = 0;
            objBusinessGroup.totalCredit = 0;
            objBusinessGroup.checked = false;
            objBusinessGroup.collapsed = 'collapsed';
            objBusinessGroup.bills = response.data;
            objBusinessGroup.credits = [];
            objBusinessGroup.info = selectedInvestor.grupoEmpresariales
              .map(
                (grupoEmpresarial) =>
                  grupoEmpresarial.scGrupo === value && grupoEmpresarial
              )
              .filter(Boolean)[0];
            for (var i = 0; i < response.data.length; i++) {
              objBusinessGroup.bills[i].key =
                objBusinessGroup.bills[i].scFactura +
                '-' +
                value +
                '-' +
                selectedInvestor.scFondeador;
              objBusinessGroup.bills[i].detail = (
                <div
                  dangerouslySetInnerHTML={{
                    __html: objBusinessGroup.bills[i].calculo,
                  }}
                ></div>
              );
              objBusinessGroup.totalOffer += objBusinessGroup.bills[i].vrOferta;
              if (objBusinessGroup.bills[i].notasCredito.length > 0) {
                objBusinessGroup.bills[i].notasCredito.forEach((credit) => {
                  objBusinessGroup.totalCredit += credit.vrFacturaRest;
                  credit.key =
                    credit.scFactura +
                    '-' +
                    value +
                    '-' +
                    selectedInvestor.scFondeador;
                  objBusinessGroup.credits.push(credit);
                });
                objBusinessGroup.totalCredit += objBusinessGroup.bills[
                  i
                ].notasCredito.reduce(this.creditReducer, 0);
              }

              objBusinessGroup.localizacion = response.metadata.localizacion;
              objBusinessGroup.cdTipoFe = response.metadata.cdTipoFe;
              objBusinessGroup.cdMoneda = response.metadata.cdMoneda;
            }
            this.setState({
              ...this.state,
              selectedBusinessGroup: objBusinessGroup.info,
              listedBills: [objBusinessGroup],
              loading: false,
            });
          })
          .catch((_) => {
            this.setState({
              ...this.state,
              redirect: true,
              loading: false,
            });
          });
      } else {
        const objInvestor = selectedInvestor ? selectedInvestor : e;
        var selectedBills = [];
        if (objInvestor) {
          objInvestor.grupoEmpresariales.forEach((grupoEmpresarial) => {
            billServices
              .listarOfertaCompra(
                token,
                selectedCompany,
                objInvestor.scFondeador,
                grupoEmpresarial.scGrupo
              )
              .then((response) => {
                selectedBills = [];
                selectedBills.info = grupoEmpresarial;
                selectedBills.checked = false;
                selectedBills.totalOffer = response.data.reduce(
                  this.offerReducer,
                  0
                );
                selectedBills.totalCredit = 0;
                selectedBills.collapsed = 'collapsed';
                selectedBills.credits = [];
                response.data.forEach((bill) => {
                  bill.key =
                    bill.scFactura +
                    '-' +
                    grupoEmpresarial.scGrupo +
                    '-' +
                    objInvestor.scFondeador;
                  bill.detail = (
                    <div
                      dangerouslySetInnerHTML={{ __html: bill.calculo }}
                    ></div>
                  );
                  if (bill.notasCredito.length > 0) {
                    bill.notasCredito.forEach((credit) => {
                      selectedBills.totalCredit += credit.vrFacturaRest;
                      credit.key =
                        credit.scFactura +
                        '-' +
                        grupoEmpresarial.scGrupo +
                        '-' +
                        objInvestor.scFondeador;
                      selectedBills.credits.push(credit);
                    });
                  }
                });
                selectedBills.bills = response.data;
                selectedBills.billFilter = response.data;

                selectedBills.localizacion = response.metadata.localizacion;
                selectedBills.cdTipoFe = response.metadata.cdTipoFe;
                selectedBills.cdMoneda = response.metadata.cdMoneda;

                this.setState({
                  ...this.state,
                  listedBills: [...this.state.listedBills, selectedBills],
                  loading: false,
                });
              })
              .catch(() => {
                this.setState({
                  ...this.state,
                  redirect: true,
                  loading: false,
                });
              });
          });
        } else {
          this.setState({
            ...this.state,
            loading: false,
          });
        }
      }
    } else {
      const { fechaSeleccionada } = this.state;
      if (value !== 'all') {
        billServices
          .listarPreOfertaProgramacion(
            token,
            selectedCompany,
            selectedInvestor.scFondeador,
            fechaSeleccionada,
            value
          )
          .then((response) => {
            var objBusinessGroup = {};
            objBusinessGroup.totalOffer = 0;
            objBusinessGroup.totalCredit = 0;
            objBusinessGroup.checked = false;
            objBusinessGroup.collapsed = 'collapsed';
            objBusinessGroup.bills = response.data;
            objBusinessGroup.credits = [];
            objBusinessGroup.info = selectedInvestor.grupoEmpresariales
              .map(
                (grupoEmpresarial) =>
                  grupoEmpresarial.scGrupo === value && grupoEmpresarial
              )
              .filter(Boolean)[0];
            for (var i = 0; i < response.data.length; i++) {
              objBusinessGroup.bills[i].key =
                objBusinessGroup.bills[i].scFactura +
                '-' +
                value +
                '-' +
                selectedInvestor.scFondeador;
              objBusinessGroup.bills[i].detail = (
                <div
                  dangerouslySetInnerHTML={{
                    __html: objBusinessGroup.bills[i].calculo,
                  }}
                ></div>
              );
              objBusinessGroup.totalOffer += objBusinessGroup.bills[i].vrOferta;
              if (objBusinessGroup.bills[i].notasCredito.length > 0) {
                objBusinessGroup.bills[i].notasCredito.forEach((credit) => {
                  objBusinessGroup.totalCredit += credit.vrFacturaRest;
                  credit.key =
                    credit.scFactura +
                    '-' +
                    value +
                    '-' +
                    selectedInvestor.scFondeador;
                  objBusinessGroup.credits.push(credit);
                });
                objBusinessGroup.totalCredit += objBusinessGroup.bills[
                  i
                ].notasCredito.reduce(this.creditReducer, 0);
              }
            }
            this.setState({
              ...this.state,
              programSelectedBusinessGroup: objBusinessGroup.info,
              programListedBills: [objBusinessGroup],
              loading: false,
            });
          })
          .catch((error) => {
            this.setState({
              ...this.state,
              redirect: true,
              loading: false,
            });
          });
      } else {
        const objInvestor = selectedInvestor ? selectedInvestor : e;
        var selectedBills = [];
        if (objInvestor) {
          objInvestor.grupoEmpresariales.forEach((grupoEmpresarial) => {
            billServices
              .listarPreOfertaProgramacion(
                token,
                selectedCompany,
                objInvestor.scFondeador,
                fechaSeleccionada,
                grupoEmpresarial.scGrupo
              )
              .then((response) => {
                selectedBills = [];
                selectedBills.info = grupoEmpresarial;
                selectedBills.checked = false;
                selectedBills.totalOffer = response.data.reduce(
                  this.offerReducer,
                  0
                );
                selectedBills.totalCredit = 0;
                selectedBills.collapsed = 'collapsed';
                selectedBills.credits = [];
                response.data.forEach((bill) => {
                  bill.key =
                    bill.scFactura +
                    '-' +
                    grupoEmpresarial.scGrupo +
                    '-' +
                    objInvestor.scFondeador;
                  bill.detail = (
                    <div
                      dangerouslySetInnerHTML={{ __html: bill.calculo }}
                    ></div>
                  );
                  if (bill.notasCredito.length > 0) {
                    bill.notasCredito.forEach((credit) => {
                      selectedBills.totalCredit += credit.vrFacturaRest;
                      credit.key =
                        credit.scFactura +
                        '-' +
                        grupoEmpresarial.scGrupo +
                        '-' +
                        objInvestor.scFondeador;
                      selectedBills.credits.push(credit);
                    });
                  }
                });
                selectedBills.bills = response.data;
                this.setState({
                  ...this.state,
                  programListedBills: [
                    ...this.state.programListedBills,
                    selectedBills,
                  ],
                  loading: false,
                });
              })
              .catch((error) => {
                this.setState({
                  ...this.state,
                  redirect: true,
                  loading: false,
                });
              });
          });
        } else {
          this.setState({
            ...this.state,
            loading: false,
          });
        }
      }
    }
  };

  getCheckboxProps = (record) => {
    var selectedInvestor = '';
    if (this.props.module === 'program') {
      selectedInvestor = this.state.programSelectedInvestor;
    } else {
      selectedInvestor = this.state.selectedInvestor;
    }
    var config = {};
    var bills;
    if (this.props.module === 'program') {
      bills = store.getState().billing.programSelectedBills;
    } else {
      bills = store.getState().billing.selectedBills;
    }

    if (bills.length > 0) {
      const selected = bills.find((bill) => {
        return bill.scFactura === record.scFactura;
      });
      if (selected && selected.scFondeador !== selectedInvestor.scFondeador) {
        config.disabled = true;
        record.inCart = selected.scNombreFondeador;
      } else {
        config.selected = true;
      }
    }
    return config;
  };

  popNotification = (message, title) => {
    notification.warning({
      message: title,
      description: message,
      duration: 4,
    });
  };

  onSelect = (record, selected, businessGroup) => {
    var currentState = store.getState().billing;
    var selectedInvestor = '';
    var module = '';
    var selectedBillsModule = '';
    var totalModule = '';
    if (this.props.module === 'program') {
      selectedInvestor = this.state.programSelectedInvestor;
      module = 'programSelectedKeys';
      selectedBillsModule = 'programSelectedBills';
      totalModule = 'programTotalSelectedValue';
    } else {
      selectedInvestor = this.state.selectedInvestor;
      module = 'selectedKeys';
      selectedBillsModule = 'selectedBills';
      totalModule = 'totalSelectedValue';
    }
    var message = '',
      title = '';
    if (selected) {
      if (record.notasCredito.length <= 0) {
        var societyBills = businessGroup.bills.filter(
          (bill) => bill.nombrePagador === record.nombrePagador
        );
        var societyBillsCredits = societyBills.filter(
          (bill) => bill.notasCredito.length > 0
        );
        if (societyBillsCredits.length > 0) {
          societyBillsCredits.forEach((bill) => {
            var exists = currentState[module].find((key) => {
              var explodeKey = key.split('-');
              return explodeKey[0] === bill.scFactura;
            });
            if (!exists) {
              message =
                'Debes seleccionar primero las facturas que tengan notas crédito asociadas, marcadas con "N"';
              title = 'Notas crédito pendientes';
              return false;
            }
          });
        }
        if (message != '') {
          return this.popNotification(message, title);
        }
      }

      currentState[module] = [...currentState[module], record.key];
      var utc = new Date().toJSON().slice(0, 10);

      const newBill = {
        feIngreso: utc,
        scFactura: record.scFactura,
        scFondeador: selectedInvestor.scFondeador,
        scProveedor: currentState.company,
        scpersonaModifica: '',
        vrOferta: record.vrOferta,
        scNombreFondeador: selectedInvestor.nombreFondeador,
        cufe: record.cufe,
        cdTipoFe: record.cdTipoFe,
        cdFactura: record.cdFactura,
        tipo: 'O',
      };
      var notasCredito = 0;
      if (record.notasCredito.length > 0) {
        notasCredito = record.notasCredito.reduce(this.creditReducer, 0);
      }
      currentState[selectedBillsModule] = [
        ...currentState[selectedBillsModule],
        newBill,
      ];
      currentState[totalModule] += newBill.vrOferta;
      currentState[totalModule] -= notasCredito;
    } else {
      if (record.notasCredito.length <= 0) {
        const disabledKey = record.key.split('-');
        var newSelectedRowKeys = currentState[module].filter((key) => {
          return key !== record.key;
        });
        currentState[totalModule] -= record.vrOferta;
        currentState[module] = newSelectedRowKeys;
        currentState[selectedBillsModule] = currentState[
          selectedBillsModule
        ].filter((bill) => {
          return (
            bill.scFactura + '-' + disabledKey[1] + '-' + bill.scFondeador !==
            record.key
          );
        });
      } else {
        var message = '',
          title = '';
        var societyBills = businessGroup.bills.filter(
          (bill) => bill.nombrePagador === record.nombrePagador
        );
        var societySelectedBills = [];
        societyBills.forEach((bill, key) => {
          var exists = currentState[selectedBillsModule].find(
            (selectedBill) =>
              selectedBill.scFactura === bill.scFactura &&
              selectedBill.scFactura !== record.scFactura
          );
          if (exists) {
            societySelectedBills.push(societyBills[key]);
          }
        });
        if (societySelectedBills.length > 0) {
          societySelectedBills.forEach((bill) => {
            if (bill.notasCredito.length <= 0) {
              message =
                'Todas las facturas asociadas a notas crédito deben de estar seleccionadas antes que las demás';
              title = 'Facturas con nota credito seleccionadas';
              return false;
            }
          });
          if (message != '') {
            return this.popNotification(message, title);
          } else {
            const disabledKey = record.key.split('-');
            var newSelectedRowKeys = currentState[module].filter((key) => {
              return key !== record.key;
            });
            var notasCredito = 0;
            if (record.notasCredito.length > 0) {
              notasCredito = record.notasCredito.reduce(this.creditReducer, 0);
            }
            currentState[totalModule] -= record.vrOferta;
            currentState[totalModule] += notasCredito;
            currentState[module] = newSelectedRowKeys;
            currentState[selectedBillsModule] = currentState[
              selectedBillsModule
            ].filter((bill) => {
              return (
                bill.scFactura +
                  '-' +
                  disabledKey[1] +
                  '-' +
                  bill.scFondeador !==
                record.key
              );
            });
          }
        } else {
          const disabledKey = record.key.split('-');
          var newSelectedRowKeys = currentState[module].filter((key) => {
            return key !== record.key;
          });
          var notasCredito = 0;
          if (record.notasCredito.length > 0) {
            notasCredito = record.notasCredito.reduce(this.creditReducer, 0);
          }
          currentState[totalModule] -= record.vrOferta;
          currentState[totalModule] += notasCredito;
          currentState[module] = newSelectedRowKeys;
          currentState[selectedBillsModule] = currentState[
            selectedBillsModule
          ].filter((bill) => {
            return (
              bill.scFactura + '-' + disabledKey[1] + '-' + bill.scFondeador !==
              record.key
            );
          });
        }
      }
    }
    if (this.props.module === 'program') {
      this.props.programSelectedBills({
        programSelectedBills: currentState[selectedBillsModule],
        programTotalSelectedValue: currentState[totalModule],
      });
    } else {
      this.props.selectedBills(currentState);
    }
  };

  onSelectAll = (selected, businessGroup) => {
    var currentState = store.getState().billing;
    var selectedInvestor = '';
    var module = '';
    var selectedBillsModule = '';
    var totalModule = '';
    if (this.props.module === 'program') {
      selectedInvestor = this.state.programSelectedInvestor;
      module = 'programSelectedKeys';
      selectedBillsModule = 'programSelectedBills';
      totalModule = 'programTotalSelectedValue';
    } else {
      selectedInvestor = this.state.selectedInvestor;
      module = 'selectedKeys';
      selectedBillsModule = 'selectedBills';
      totalModule = 'totalSelectedValue';
    }
    if (selected) {
      businessGroup.bills.forEach((bill) => {
        const exists = currentState[selectedBillsModule].find(
          (selectedBill) => bill.scFactura === selectedBill.scFactura
        );
        if (!exists) {
          var utc = new Date().toJSON().slice(0, 10);
          const newBill = {
            feIngreso: utc,
            scFactura: bill.scFactura,
            scFondeador: selectedInvestor.scFondeador,
            scProveedor: currentState.company,
            vrOferta: bill.vrOferta,
            scpersonaModifica: '',
            scNombreFondeador: selectedInvestor.nombreFondeador,
            cufe: bill.cufe,
            cdTipoFe: bill.cdTipoFe,
            cdFactura: bill.cdFactura,
            tipo: 'O',
          };
          currentState[module] = [
            ...currentState[module],
            bill.scFactura +
              '-' +
              businessGroup.info.scGrupo +
              '-' +
              selectedInvestor.scFondeador,
          ];
          currentState[selectedBillsModule] = [
            ...currentState[selectedBillsModule],
            newBill,
          ];
          currentState[totalModule] += newBill.vrOferta;
        }
      });
    } else {
      businessGroup.bills.forEach((bill) => {
        const scFactura = bill.scFactura;
        const removeBill = currentState[selectedBillsModule].find(
          (bill) => bill.scFactura === scFactura
        );
        currentState[totalModule] -= removeBill.vrOferta;
        currentState[selectedBillsModule] = currentState[
          selectedBillsModule
        ].filter((bill) => {
          return bill.scFactura !== scFactura;
        });
        currentState[module] = currentState[module].filter((key) => {
          return (
            bill.scFactura +
              '-' +
              businessGroup.info.scGrupo +
              '-' +
              selectedInvestor.scFondeador !==
            key
          );
        });
      });
    }
    if (this.props.module === 'program') {
      this.props.programSelectedBills({
        programSelectedBills: currentState[selectedBillsModule],
        programTotalSelectedValue: currentState[totalModule],
        programSelectedKeys: currentState[module],
      });
    } else {
      this.props.selectedBills(currentState);
    }
  };

  nextStep = () => {
    const selectedCompany = store.getState().billing.company;
    const token = store.getState().auth.auth.access_token;
    var bills = [];
    let auxbills = [];

    if (this.props.module === 'program') {
      const { programListedBills } = this.state;
      auxbills = programListedBills;
      bills = store.getState().billing.programSelectedBills;
      const { fechaSeleccionada } = this.state;
      if (fechaSeleccionada !== '') {
        bills.forEach((bill) => {
          bill.feProgramada = fechaSeleccionada;
          bill.tipo = 'P';
        });
      } else {
        return notification.error({
          message: 'No ha seleccionado una fecha',
          description: 'Debe seleccionar una fecha para programar las facturas',
          duration: 4,
        });
      }
    } else {
      const { listedBills } = this.state;
      auxbills = listedBills;
      bills = store.getState().billing.selectedBills;
    }

    const scFacturasSelected = bills
      .filter(
        (bill) =>
          bill.cdTipoFe === 'RADIAN' &&
          (!bill.cufe || bill.cufe === '' || bill.cufe === null)
      )
      .map((bill) => bill.scFactura);

    const billsCufe = auxbills
      .map((group) => group.bills)
      .flat()
      .filter(
        (bill) =>
          scFacturasSelected.filter((sc) => bill.scFactura === sc).length > 0
      );

    if (billsCufe.length > 0) {
      notification.error({
        message: 'CUFE',
        description: `Una o mas facturas no tiene el código CUFE válido o registrado`,
        duration: 4,
      });

      return;
    }

    billServices
      .confirmarCompraOferta(token, selectedCompany, bills)
      .then((response) => {
        if (response.data !== 'error') {
          if (this.props.module === 'program') {
            this.props.programNextStep(1);
          } else {
            this.props.nextStep(1);
          }
          this.props.stepHandler();
        }
      });
  };

  changeTable = (businessGroup) => {
    var module = '';
    if (this.props.module === 'program') {
      module = 'programListedBills';
    } else {
      module = 'listedBills';
    }
    var index = this.state[module].findIndex(
      (listedBill) => businessGroup.info.scGrupo === listedBill.info.scGrupo
    );
    this.state[module][index].checked = !this.state[module][index].checked;
    this.setState({
      ...this.state,
    });
  };

  getProgramInvestors = (programDate) => {
    const { fechaSeleccionada } = this.state;
    if (fechaSeleccionada !== '' || programDate) {
      this.setState({
        ...this.state,
        loading: true,
        fechaSeleccionada: fechaSeleccionada || programDate.format('YYYYMMDD'),
      });
      const selectedCompany = store.getState().billing.company;
      const token = store.getState().auth.auth.access_token;
      var selectedDate = programDate
        ? programDate.format('YYYYMMDD')
        : fechaSeleccionada;
      billServices
        .resumenCompraOfertaProgramacion(token, selectedCompany, selectedDate)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            if (this.props.billing.programSelectedBills.length > 0) {
              var selectedInvestor = response.data.find(
                (investor) =>
                  investor.scFondeador ===
                  this.props.billing.programSelectedBills[0].scFondeador
              );
            }

            this.setState({
              programSelectedInvestor: selectedInvestor || '',
              programSelectedBusinessGroup: '',
              programListedBills: [],
              totalOffer: 0,
              selectedRowKeys: [],
              selectedRows: [],
              investors: response.data,
              loading: false,
            });
          } else {
            history.push(`${process.env.PUBLIC_URL}/error`);
          }
        });
    } else {
      this.setState({
        programSelectedInvestor: '',
        programSelectedBusinessGroup: '',
        programListedBills: [],
        totalOffer: 0,
        selectedRowKeys: [],
        selectedRows: [],
        investors: [],
        loading: false,
      });
    }
  };

  updateProgramDate = (date) => {
    this.state.fechaSeleccionada = date ? date.format('YYYYMMDD') : '';
    this.getProgramInvestors();
    this.props.updateProgramDate({ programDate: date ? date : '' });
  };

  openTable = (businessGroup) => {
    var module = '';
    if (this.props.module === 'program') {
      module = 'programListedBills';
    } else {
      module = 'listedBills';
    }
    var index = this.state[module].findIndex(
      (listedBill) => businessGroup.info.scGrupo === listedBill.info.scGrupo
    );
    this.state[module][index].collapsed =
      this.state[module][index].collapsed === '' ? 'collapsed' : '';
    this.setState({
      ...this.state,
    });
  };

  disabledDate = (date) => {
    const { fechasActivas } = this.state;
    if (Array.isArray(fechasActivas) && fechasActivas.length === 6) {
      return (
        date < moment().endOf('day') ||
        (date.format('YYYY-MM-DD') !== fechasActivas[0].format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') !== fechasActivas[1].format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') !== fechasActivas[2].format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') !== fechasActivas[3].format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') !== fechasActivas[4].format('YYYY-MM-DD') &&
          date.format('YYYY-MM-DD') !== fechasActivas[5].format('YYYY-MM-DD'))
      );
    } else {
      return date < moment().endOf('day');
    }
  };

  billsExcel = () => {
    const selectedCompany = store.getState().billing.company;
    const token = store.getState().auth.auth.access_token;
    this.setState({
      ...this.state,
      loading: true,
    });
    let scFondeador = '';
    let scGrupo = '';

    if (this.props.module === 'program') {
      scFondeador = this.state.programSelectedInvestor.scFondeador;
      scGrupo = this.state.programSelectedBusinessGroup.scGrupo;
      billServices
        .programacionPreofertasExcel(token, selectedCompany, scFondeador, {
          scGrupo: scGrupo,
          fechaOferta: moment(store.getState().billing.programDate).format(
            'YYYYMMDD'
          ),
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response], { type: 'application/vnd.ms-excel' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Preofertas.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            ...this.state,
            loading: false,
          });
        });
    } else {
      scFondeador = this.state.selectedInvestor.scFondeador;
      scGrupo = this.state.selectedBusinessGroup.scGrupo;
      billServices
        .preofertasExcel(token, selectedCompany, scFondeador, scGrupo)
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response], { type: 'application/vnd.ms-excel' })
          );
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Preofertas.xlsx`);
          document.body.appendChild(link);
          link.click();
          this.setState({
            ...this.state,
            loading: false,
          });
        });
    }
  };

  handleCufeOnClose = (offer) => {
    if (!offer) {
      this.setState({
        ...this.state,
        cufeVisible: false,
      });
    } else {
      if (this.props.module === 'program') {
        const { programListedBills } = this.state;

        const aux = programListedBills.map((group) => {
          const bills = group.bills.map((bill) => {
            if (bill.scFactura === offer.scFactura) {
              return { ...bill, cufe: offer.cufe };
            } else {
              return bill;
            }
          });

          return {
            ...group,
            bills: [...bills],
          };
        });

        this.setState({
          ...this.state,
          cufeVisible: false,
          offerCufe: {},
          programListedBills: [...aux],
        });
      } else {
        const { listedBills } = this.state;

        const aux = listedBills.map((group) => {
          const bills = group.bills.map((bill) => {
            if (bill.scFactura === offer.scFactura) {
              return { ...bill, cufe: offer.cufe };
            } else {
              return bill;
            }
          });

          return {
            ...group,
            bills: [...bills],
          };
        });

        this.setState({
          ...this.state,
          cufeVisible: false,
          offerCufe: {},
          listedBills: [...aux],
        });
      }
    }
  };

  openCufeMasivoModal = (oferta) => {
    this.setState({
      ...this.state,
      cufeMasivoVisible: true,
    });
  };

  handleCufeMasivoFinish = async (files) => {
    var currentState = store.getState().billing;
    const token = store.getState().auth.auth.access_token;

    const result = await billServices.importCufeMasivo(
      token,
      currentState.company,
      files
    );

    const data = result.data;
    if (
      data !== undefined &&
      data.totalFacturas !== undefined &&
      data.totalFacturasFallidas !== undefined
    ) {
      const selectedCompany = this.props.billing.company;
      this.setState({
        ...this.state,
        loading: true,
      });
      const token = store.getState().auth.auth.access_token;
      billServices
        .listarPreofertas(token, selectedCompany)
        .then((response) => {
          if (response.data.length > 0) {
            this.setState({
              selectedInvestor: '',
              selectedBusinessGroup: '',
              listedBills: [],
              totalOffer: 0,
              selectedRowKeys: [],
              selectedRows: [],
              investors: response.data,
              loading: false,
            });
          } else {
            this.setState({
              ...this.state,
              redirect: true,
              loading: false,
            });
          }
        })
        .catch((error) => {
          notification.error({
            message: 'Ha ocurrido un error',
            description: error.response.data.message,
            duration: 4,
          });
        });
    }

    return result;
  };

  handleCufeMasivoOnClose = () => {
    this.setState({
      ...this.state,
      cufeMasivoVisible: false,
    });
  };

  onSearchValueColumn = (businessGroup, textSearch) => {
    const { listedBills } = this.state;
    const scGrupo = businessGroup.info.scGrupo;

    this.setState({
      ...this.state,
      loading: true,
    });

    const grupo = listedBills.find((value) => scGrupo === value.info.scGrupo);

    const bills = grupo.billFilter;
    if (textSearch.trim().length > 0) {
      const formattedSearch = textSearch.replace(/\s+/g, '');
      const regexPattern = new RegExp(formattedSearch, 'i');
      const filteredBills = bills.filter((bill) =>
        regexPattern.test(bill.cdFactura)
      );
      grupo.bills = filteredBills;
    } else {
      grupo.bills = bills;
    }

    this.state.listedBills = listedBills.map((value) =>
      value.info.scGrupo === grupo.info.scGrupo ? grupo : value
    );

    this.setState({
      ...this.state,
      loading: false,
    });
  };

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: `${process.env.PUBLIC_URL}/error`,
          }}
        />
      );
    }

    var listedBills = [];
    if (this.props.module === 'program') {
      listedBills = this.state.programListedBills;
    } else {
      listedBills = this.state.listedBills;
    }
    const { investors } = this.state;
    var selectedInvestor = '';
    if (this.props.module === 'program') {
      selectedInvestor = this.state.programSelectedInvestor;
    } else {
      selectedInvestor = this.state.selectedInvestor;
    }
    var selectedBusinessGroup = '';
    if (this.props.module === 'program') {
      selectedBusinessGroup = this.state.programSelectedBusinessGroup;
    } else {
      selectedBusinessGroup = this.state.selectedBusinessGroup;
    }
    var selectedKeys = [];
    if (this.props.module === 'program') {
      selectedKeys = store.getState().billing.programSelectedKeys;
    } else {
      selectedKeys = store.getState().billing.selectedKeys;
    }
    var selectedBills = [];
    if (this.props.module === 'program') {
      selectedBills = store.getState().billing.programSelectedBills;
    } else {
      selectedBills = store.getState().billing.selectedBills;
    }

    const selectedCompany = store.getState().billing.company;
    const token = store.getState().auth.auth.access_token;
    const listedBill = listedBills.length > 0 ? listedBills[0] : undefined;

    return (
      <div className='bill-style'>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          {this.props.module === 'program' ? (
            <div>
              <Card className='card-shadow card-radius default-border welcome-message '>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div className='title'>{PROGRAM_TITLE_INIT}</div>
                  </Col>
                </Row>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div className='subtitle'>{PROGRAM_SUBTITLE_INIT}</div>
                  </Col>
                </Row>
              </Card>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className='title-info'>Elige la fecha</div>
                </Col>
              </Row>
              <Col span={6} className='date-container'>
                <DatePicker
                  size='small'
                  format='YYYY-MM-DD'
                  value={store.getState().billing.programDate}
                  disabledDate={this.disabledDate}
                  className='program-calendar'
                  onChange={(date, string) => this.updateProgramDate(date)}
                  inputReadOnly
                />
              </Col>
            </div>
          ) : (
            ''
          )}
          {investors.length > 0 && (
            <div>
              {this.props.module !== 'program' && (
                <Card className='card-shadow card-radius default-border welcome-message '>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <div className='title'>{BILLING_TITLE_INIT}</div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <div className='subtitle'>{BILLING_SUBTITLE_INIT}</div>
                    </Col>
                  </Row>
                </Card>
              )}
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <div className='title-info'>Elige el fondeador final</div>
                </Col>
              </Row>
            </div>
          )}
          <Row gutter={[16, 16]} className='investors-container'>
            {investors.map((investor, key) => {
              return (
                <Col
                  xl={{ span: 6 }}
                  lg={{ span: 8 }}
                  md={{ span: 12 }}
                  key={investor.scFondeador + '-' + key}
                >
                  <Card
                    ref={(cards) => (this.cards[key] = cards)}
                    className={
                      'investor-card card-radius default-border card-shadow ' +
                      (selectedInvestor.scFondeador === investor.scFondeador
                        ? ' active selected'
                        : '')
                    }
                    id={investor.scFondeador}
                    onClick={() => this.investorSelection(investor)}
                  >
                    <div className='title'>
                      {investor.nombreFondeador.toUpperCase()}
                    </div>
                    <div className={'logo-container'}>
                      <img
                        src={
                          investor.dsLogo
                            ? investor.dsLogo
                            : 'https://resources-affirmatum.s3.amazonaws.com/Pronto_Pago_MF.png'
                        }
                        alt=''
                        className='logo'
                      />
                    </div>
                    <div className='value'>
                      {currencyFormat(investor.vrOfertaTotal)}
                    </div>
                    <div className='payers'>
                      {investor.grupoEmpresariales.map((grupoEmpresarial) => (
                        <p key={`${grupoEmpresarial.scGrupo}_key`}>
                          {grupoEmpresarial.dsNombres}
                        </p>
                      ))}
                    </div>
                    <div className='other-data'>
                      Desembolso: {investor.fechaDesembolso}
                    </div>
                    <div className='other-data'>
                      Nro. Facturas: {investor.nroFacturas}
                    </div>
                    <div className='conditions'>
                      <a
                        href='#'
                        onClick={() => this.handleOpenCondicionModal(investor)}
                      >
                        VER CONDICIONES
                      </a>
                    </div>
                    {!investor.aprobado && (
                      <div className='disabled-investor'>
                        <p>{BILLING_TIME_ERROR}</p>
                      </div>
                    )}
                  </Card>
                </Col>
              );
            })}
          </Row>

          {selectedInvestor !== '' && (
            <div id='bill-selection-section'>
              {selectedInvestor.mensajeFondeador && (
                <Row gutter={30} className='bill-selection' align='middle'>
                  <Col span={24}>
                    <Card className='card-shadow card-radius default-border welcome-message '>
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          <div
                            className='subtitle'
                            dangerouslySetInnerHTML={{
                              __html: `<strong> <em> ${selectedInvestor.mensajeFondeador}  </em> </strong>`,
                            }}
                          ></div>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              )}
              <Row gutter={30} className='bill-selection' align='middle'>
                <Col>
                  <span className='title'>Elige facturas</span>
                </Col>
                <Col>
                  <div className='select-label'>
                    Filtrar por grupo empresarial:
                  </div>
                </Col>
                <Col span={4}>
                  <div className='select-container'>
                    <Select
                      size='small'
                      defaultValue={'all'}
                      value={selectedBusinessGroup.scGrupo}
                      onChange={this.businessGroupSelection}
                    >
                      <Option key={'all'} value={'all'}>
                        TODOS
                      </Option>
                      {selectedInvestor.grupoEmpresariales.map(
                        (grupoEmpresarial) => (
                          <Option
                            key={grupoEmpresarial.scGrupo}
                            value={grupoEmpresarial.scGrupo}
                          >
                            {grupoEmpresarial.dsNombres.toUpperCase()}
                          </Option>
                        )
                      )}
                    </Select>
                  </div>
                </Col>
                <Col span={2}>
                  <span className='button'>
                    <Button
                      size='small'
                      className='btn-excel'
                      onClick={this.billsExcel}
                      title='Exportar a excel'
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
                {listedBill && listedBill.cdTipoFe === 'RADIAN' && (
                  <Col span={2} collapsed={8}>
                    <span className='button'>
                      <Button
                        size='small'
                        className='btn btn-upload'
                        onClick={this.openCufeMasivoModal}
                        title='Adicionar CUFE'
                        icon={<CloudUploadOutlined />}
                      >
                        Adicionar CUFE
                      </Button>
                    </span>
                  </Col>
                )}
              </Row>
              {listedBills.map((businessGroup, key) => (
                <Row
                  gutter={[16, 16]}
                  className='bill-table-container'
                  key={businessGroup.info.scGrupo}
                  key={
                    key +
                    '-' +
                    selectedInvestor.scFondeador +
                    '-' +
                    businessGroup.info.scGrupo
                  }
                >
                  <Col span={24}>
                    <Card className='card-shadow card-radius default-border card'>
                      <Row>
                        <Col
                          xl={{ span: 6 }}
                          md={{ span: 6 }}
                          className='client'
                        >
                          {businessGroup.info.dsNombres.toUpperCase()}
                        </Col>
                        <Col
                          xl={{ span: 3 }}
                          md={{ span: 6 }}
                          className='select-label'
                        >
                          Facturas/Notas crédito
                        </Col>
                        <Col
                          xl={{ span: 4 }}
                          md={{ span: 4 }}
                          className='switch-container'
                        >
                          <Switch
                            checkedChildren='Notas crédito'
                            checked={businessGroup.checked}
                            unCheckedChildren='Facturas'
                            onChange={() => this.changeTable(businessGroup)}
                          />
                        </Col>
                        <Col span={4}>
                          <Search
                            placeholder='Buscar Facturas'
                            allowClear
                            onSearch={(e) =>
                              this.onSearchValueColumn(businessGroup, e)
                            }
                          />
                        </Col>
                        <Col
                          xl={{ span: 2 }}
                          md={{ span: 4 }}
                          className='show-icon-container'
                        >
                          <CaretDownOutlined
                            className={
                              businessGroup.collapsed ? '' : 'caret-open'
                            }
                            onClick={() => this.openTable(businessGroup)}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <Row
                        gutter={[16, 16]}
                        className={'table-collapse ' + businessGroup.collapsed}
                      >
                        <Col span={24}>
                          {businessGroup.credits.length > 0 ||
                          businessGroup.bills.length > 0 ? (
                            <Table
                              className={
                                businessGroup.checked ? 'no-check' : ''
                              }
                              rowSelection={{
                                type: 'checkbox',
                                onSelectAll: (selected) =>
                                  this.onSelectAll(selected, businessGroup),
                                onSelect: (record, selected) =>
                                  this.onSelect(
                                    record,
                                    selected,
                                    businessGroup
                                  ),
                                selectedRowKeys: selectedKeys,
                                getCheckboxProps: this.getCheckboxProps,
                              }}
                              pagination={{
                                defaultPageSize: 10,
                                showSizeChanger: true,
                                pageSizeOptions: ['10', '20', '30'],
                              }}
                              columns={
                                businessGroup.checked
                                  ? this.creditsColumns
                                  : this.getBillsColumns(businessGroup.cdTipoFe)
                              }
                              dataSource={
                                businessGroup.checked
                                  ? businessGroup.credits
                                  : businessGroup.bills
                              }
                            ></Table>
                          ) : (
                            <p className='empty-bills'>
                              Las facturas en oferta no cubren el valor de notas
                              crédito
                            </p>
                          )}
                        </Col>
                      </Row>
                      <Row className='footer'>
                        <Col className='text'>Hoy te ofrecemos:</Col>
                        <Col className='value value-offer'>
                          {currencyFormat(businessGroup.totalOffer)}
                        </Col>
                        <Col className='text'>Notas crédito:</Col>
                        <Col className='value value-credit'>
                          {currencyFormat(businessGroup.totalCredit)}
                        </Col>
                        <Col className='text text-total'>Total:</Col>
                        <Col className='value value-credit'>
                          {currencyFormat(
                            businessGroup.totalOffer - businessGroup.totalCredit
                          )}
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              ))}
            </div>
          )}

          {!this.state.loading && (
            <Row gutter={[16, 16]}>
              <Col span={24} className='align-center'>
                <Button
                  disabled={selectedBills.length <= 0}
                  className={`btn-next ${
                    selectedBills.length <= 0 ? 'disabled' : ''
                  }`}
                  onClick={this.nextStep}
                >
                  Siguiente
                </Button>
              </Col>
            </Row>
          )}
        </Spin>
        <CufeModal
          oferta={this.state.offerCufe}
          visible={this.state.cufeVisible}
          onClose={this.handleCufeOnClose}
          token={token}
          company={selectedCompany}
        />
        <CondicionesModal
          data={this.state.condicionDataFondeador}
          visible={this.state.condicionVisible}
          onClose={this.handleCondicionOnClose}
          token={token}
        />
        <CufeMasivoModal
          visible={this.state.cufeMasivoVisible}
          onFinish={this.handleCufeMasivoFinish}
          onClose={this.handleCufeMasivoOnClose}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  billing: state.billing,
});

export default connect(mapStateToProps, {
  selectedBills,
  nextStep,
  programNextStep,
  programSelectedBills,
  updateProgramDate,
})(Bill);
